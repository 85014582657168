
import { GenericPopUpClose, GenericPopUpInit } from "./genericPopup.js";
import { setCookieValue } from "./utilities.js";

const emergencyBanner = () => {

  const emergencyMessageSelector = '#emergency-message';

  const onClose = () => {
    let cookieName = $(emergencyMessageSelector).children('a').attr('data');
    setCookieValue(cookieName, true, 1);
    GenericPopUpClose();
  }

  GenericPopUpInit();
  $(emergencyMessageSelector).children('.m-popup-new__close').off('click', onClose).on('click', onClose);

}

window.site.doc.ajaxComplete(function() {
  emergencyBanner();
});

export default emergencyBanner;

